exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-paycor-js": () => import("./../../../src/pages/careers-paycor.js" /* webpackChunkName: "component---src-pages-careers-paycor-js" */),
  "component---src-pages-layouts-js": () => import("./../../../src/pages/layouts.js" /* webpackChunkName: "component---src-pages-layouts-js" */),
  "component---src-templates-career-career-template-jsx": () => import("./../../../src/templates/career/career.template.jsx" /* webpackChunkName: "component---src-templates-career-career-template-jsx" */),
  "component---src-templates-case-study-case-study-template-jsx": () => import("./../../../src/templates/case-study/case-study.template.jsx" /* webpackChunkName: "component---src-templates-case-study-case-study-template-jsx" */),
  "component---src-templates-news-news-template-jsx": () => import("./../../../src/templates/news/news.template.jsx" /* webpackChunkName: "component---src-templates-news-news-template-jsx" */),
  "component---src-templates-page-page-template-jsx": () => import("./../../../src/templates/page/page.template.jsx" /* webpackChunkName: "component---src-templates-page-page-template-jsx" */),
  "component---src-templates-post-post-template-jsx": () => import("./../../../src/templates/post/post.template.jsx" /* webpackChunkName: "component---src-templates-post-post-template-jsx" */),
  "component---src-templates-white-paper-white-paper-template-jsx": () => import("./../../../src/templates/white-paper/white-paper.template.jsx" /* webpackChunkName: "component---src-templates-white-paper-white-paper-template-jsx" */)
}

